import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import "./tailwind.css";
import Div100vh from "react-div-100vh";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Div100vh>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Div100vh>
);
