import React from "react";

import landingPageVideoH265 from "../../LandingPage.mp4";
import landingPageVideoPoster from "../../LandingPage.webp";
import landingPagePortraitVideoH265 from "../../LandingPagePortrait.mp4";
import landingPagePortraitVideoPoster from "../../LandingPagePortrait.webp";

import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
.landingContainer {
    padding: 30px;
    width: 100vw;
    height: 100%;
}
.titleStyle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 99999999;
}
.noSelect {
    webkit-touch-callout: none;
    -webkit-user-select: none;
    khtml-user-select: none;
    moz-user-select: none;
    ms-user-select: none;
    user-select: none;
}
.nameStyle {
    color: #ededed;
    font-size: 60px;
    font-weight: 550;
    padding: 15;
    border-radius: 8px;
}
.enterStyle {
    color: #ededed;
    font-size: 20px;
    border: 1px solid #ededed;
    background: rgba(255, 255, 255, 0.2);
    padding: 5px 25px 5px 25px;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px
}
.enterStyle:hover {
    background: rgba(255, 255, 255, 0.3);
}
.enterStyle:active {
    background: rgba(255, 255, 255, 0.4);
}
@media (max-width: 760px) {
    .landingContainer {
        padding: 10px;
    }
    .nameStyle {
        font-size: 50px;
    }
    .enterStyle {
        font-size: 18px;
    }
}
@media (max-width: 600px) {
    .landingContainer {
        padding: 10px;
    }
    .nameStyle {
        font-size: 30px;
    }
    .enterStyle {
        font-size: 18px;
    }

}
@media (max-width: 400px) {
    .landingContainer {
        padding: 10px;
    }
    .nameStyle {
        font-size: 25px;
    }
    .enterStyle {
        font-size: 18px;
    }
}
@media (max-width: 310px) {
    .landingContainer {
        padding: 10px;
    }
    .nameStyle {
        font-size: 20px;
    }
    .enterStyle {
        font-size: 18px;
    }
}
`;

const Landing = ({ isMobile }) => {
  console.log(isMobile);
  return (
    <>
      <GlobalStyles />
      <div className="landingContainer">
        <div className="titleStyle noSelect">
          <span className="nameStyle noSelect">ALEX MORRISSEY-SMITH</span>
          <a href="/work" className="enterStyle noSelect">
            Enter
          </a>
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            background: "transparent",
          }}
        >
          {!isMobile ? (
            <video
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: 20,
                border: "0px solid transparent",
                outline: "0px solid transparent",
              }}
              autoPlay={true}
              loop={true}
              muted={true}
              playsInline={true}
              poster={landingPageVideoPoster}
            >
              <source src={landingPageVideoH265}></source>
            </video>
          ) : (
            <video
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: 20,
                border: "0px solid transparent",
                outline: "0px solid transparent",
              }}
              autoPlay={true}
              loop={true}
              muted={true}
              playsInline={true}
              poster={landingPagePortraitVideoPoster}
            >
              <source src={landingPagePortraitVideoH265}></source>
            </video>
          )}
        </div>
      </div>
    </>
  );
};

export default Landing;
