import React, { useState } from "react";
import { createGlobalStyle } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

const GlobalStyles = createGlobalStyle`
  .sidebar {
    width: 330px; 
    min-width: 330px; 
    background-color: #f4f4f4;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 99999;
  }

  .sidebar-inner {
    width: 330px; 
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: flex-start;
    z-index: 99999;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }


  .name {
    font-size: 23px;
    color: #333;
    margin-bottom: 20px;
  }

  @media (max-width: 1200px) {
    .sidebar {
      width: 260px; 
      min-width: 260px; 
    }

    .sidebar-inner {
      width: 260px; 
    }

    .name {
      font-size: 18px;
    }
  }


  .menu {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .menu-item {
    margin-bottom: 10px;
  }

  .menu-link {
    text-decoration: none;
    color: #555;
    font-size: 18px;
    cursor: pointer;
  }

  .menu-link.active {
    color: rgb(20, 20, 20);
    font-weight: 580;
  }

  .submenu {
    list-style: none;
    padding: 0;
    margin: 5px 0 0 10px; /* Default margin */
  }

  .submenu-link {
    font-size: 14px;
    color: #555;
    cursor: pointer;
  }

  .submenu-link.active {
    color: rgb(20, 20, 20);
    font-weight: 580;
  }

  .content {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 768px) {
    .content {
      flex-direction: column;
    }
  }

  .noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .mobile-menu {
    overflow: hidden;
    max-height: 0; 
    background-color: #f4f4f4;
    padding: 0 20px;
    position: absolute;
    top: 60px;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: max-height 0.5s ease;
    z-index: 99999999999999
  }

  .mobile-menu.open {
    max-height: 300px;
  }

  .topbar {
    width: 100%;
    height: 60px;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .topbar-title {
    font-size: 23px;
    font-weight: normal; 
  }

  @media (max-width: 480px) {
    .topbar-title {
      font-size: 20px;
    }

    /* Adjust the submenu margin for mobile */
    .submenu {
      margin: 5px 0 0 0; /* Remove left indent for mobile */
    }
  }

  @media (max-width: 370px) {
    .topbar-title {
      font-size: 16px;
    }
  }

  @media (max-width: 340px) {
    .topbar-title {
      font-size: 14px;
    }
  }

  @media (max-width: 290px) {
    .topbar-title {
      font-size: 12px;
    }
  }

  .menu-button {
    font-size: 18px;
    background: none;
    border: none;
    cursor: pointer;
    color: #555;
  }

  .containerStyle {
    padding: 20px;
    width: 100%;
    height: 100vh;
  }
`;

const Layout = ({ children, isMobile }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const updateUrl = (category) => {
    const params = new URLSearchParams(location.search);
    params.set("category", category);
    navigate({ search: params.toString() });
    setMenuOpen(false);
  };

  const params = new URLSearchParams(location.search);
  const isAllActive =
    !params.get("category") || params.get("category") === "all";

  return (
    <>
      <GlobalStyles />
      <div className="content">
        {!isMobile ? (
          <div className="sidebar">
            <div className="sidebar-inner">
              <div className="name">ALEX MORRISSEY-SMITH</div>
              <ul className="menu">
                <li className="menu-item">
                  <div
                    className={`menu-link ${
                      isAllActive ? "active" : ""
                    } noSelect`}
                    onClick={() => updateUrl("all")}
                  >
                    ALL
                  </div>
                </li>
                <li className="menu-item">
                  <div
                    className={`menu-link ${
                      params.get("category") === "videography" ? "active" : ""
                    } noSelect`}
                    onClick={() => updateUrl("videography")}
                  >
                    VIDEOGRAPHY
                  </div>
                  <ul className="submenu">
                    <li className="submenu-item">
                      <div
                        className={`submenu-link ${
                          params.get("category") === "videography-creative"
                            ? "active"
                            : ""
                        } noSelect`}
                        onClick={() => updateUrl("videography-creative")}
                      >
                        CREATIVE
                      </div>
                    </li>
                    <li className="submenu-item">
                      <div
                        className={`submenu-link ${
                          params.get("category") === "videography-ecomm"
                            ? "active"
                            : ""
                        } noSelect`}
                        onClick={() => updateUrl("videography-ecomm")}
                      >
                        ECOMM
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="menu-item">
                  <div
                    className={`menu-link ${
                      params.get("category") === "photography" ? "active" : ""
                    } noSelect`}
                    onClick={() => updateUrl("photography")}
                  >
                    PHOTOGRAPHY
                  </div>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <>
            <div className="topbar">
              <div className="topbar-title">ALEX MORRISSEY-SMITH</div>
              <button
                className="menu-button"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                MENU
              </button>
            </div>
            <div className={`mobile-menu ${menuOpen ? "open" : ""}`}>
              <ul
                className="menu"
                style={{ paddingTop: 20, paddingBottom: 20 }}
              >
                <li className="menu-item">
                  <div
                    className={`menu-link ${
                      isAllActive ? "active" : ""
                    } noSelect`}
                    onClick={() => updateUrl("all")}
                  >
                    ALL
                  </div>
                </li>
                <li className="menu-item">
                  <div
                    className={`menu-link ${
                      params.get("category") === "videography" ? "active" : ""
                    } noSelect`}
                    onClick={() => updateUrl("videography")}
                  >
                    VIDEOGRAPHY
                  </div>
                  <ul className="submenu">
                    <li className="submenu-item">
                      <div
                        className={`submenu-link ${
                          params.get("category") === "videography-creative"
                            ? "active"
                            : ""
                        } noSelect`}
                        onClick={() => updateUrl("videography-creative")}
                      >
                        CREATIVE
                      </div>
                    </li>
                    <li className="submenu-item">
                      <div
                        className={`submenu-link ${
                          params.get("category") === "videography-ecomm"
                            ? "active"
                            : ""
                        } noSelect`}
                        onClick={() => updateUrl("videography-ecomm")}
                      >
                        ECOMM
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="menu-item">
                  <div
                    className={`menu-link ${
                      params.get("category") === "photography" ? "active" : ""
                    } noSelect`}
                    onClick={() => updateUrl("photography")}
                  >
                    PHOTOGRAPHY
                  </div>
                </li>
              </ul>
            </div>
          </>
        )}
        <div className="containerStyle">{children}</div>
      </div>
    </>
  );
};

export default Layout;
